body.contrast {
  section {
    border-top: 1px solid #999999 !important;
    border-bottom: 1px solid #999999 !important;
  }

  div,article, section, footer, nav {
    background-color: #000000 !important;
  }

  .navbar-brand {
    background-color: #ffffff !important;
  }

  h1, h2, h3, h4, h5, h6, p, span, label, article, date {
    color: #ffffff !important;
  }

  a, button {
    background: transparent !important;
    color: #0080FF !important;

    * {
      background: transparent !important;
      color: #0080FF !important;
    }
  }

  .primary-color {
    color: #40C090!important;
  }

  .primary-color-hover:hover {
    color: #40C090 !important;
  }
}
