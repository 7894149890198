//.primary-color {
//  color: #e21e30;
//}
//
//.primary-border-color {
//  border-color: #e21e30;
//}
//
//
//.primary-color-hover:hover {
//  color: #e21e30;
//}
//
//.primary-bg {
//  background-color: #e21e30;
//}

.primary-color {
  color: #c61a1b;
}

.primary-border-color {
  border-color: #c61a1b;
}


.primary-color-hover:hover {
  color: #c61a1b;
}

.primary-bg {
  background-color: #c61a1b;
}

.primary-color-alt {
  color: #e95758;
}

.primary-bg-alt {
  background-color: #e95758;
}

.white-smoke-color {
  color: #eeeeee;
}

.white-smoke-bg {
  background-color: #f9f9f9;
}

.medium-gray-color {
  color: #555555;
}

.light-gray-color {
  color: #999999;
}

.medium-gray-bg {
  color: #555555;
}

.light-pink-bg {
  background-color: #ffdfe0;
}

.light-black-bg {
  background-color: #1a1a1a;
}

.black-color {
  color: #111111;
}

.black-bg {
  background-color: #111111;
}
