.note-card {
  .featured-image {
    min-height: 230px;
    background-size: cover;
    background-position: center;
  }

  header {
    min-height: 150px;
  }

  a {
    .material-icons {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  footer {
    .avatar {
      flex: 0 0 50px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
