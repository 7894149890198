a, button {
  &:disabled {
    cursor: not-allowed ;
    background: #cccccc !important;
    color: #666666 !important;
    opacity: 0.6 !important;
  }

  &:focus {
    border: none;
    outline: none;
  }
}

form {



  div.mce_inline_error {
    font-size: 12px;
    text-align: right;
  }
}
