@import './toggle-contrast/toggle-contrast.scss';

.skip-links {
  left: 0;
  top: 62px;

  @media(max-width: 991px) {
    left: -100%;
  }
}

.navbar {
  .navbar-brand {
    img {
      width: 150px;
    }
  }
}
