@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  font-family: 'Poppins', sans-serif;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-60 {
  font-size: 60px;
}

.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.font-weight-black {
  font-weight: 900
}

.cursor-pointer {
  cursor: pointer;
}

.line-height-0-9 {
  line-height: 0.9;
}

.line-height-1 {
  line-height: 1;
}

.wysiwig-container {
  color: #555555;
  font-size: 16px;

  a {
    color: #e21e30;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      text-decoration-color: #e21e30;
    }
  }

  blockquote {
    font-style: italic;
    margin-left: 30px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  //img {
  //  width: 100%;
  //  display: block;
  //  height: auto;
  //}

  h5 {
    font-size: 18px;
    color: #111111;
    margin: 30px 0 20px 0;
  }

  h4 {
    font-size: 20px;
    color: #111111;
    margin: 30px 0 20px 0;
  }

  h3 {
    font-size: 25px;
    color: #111111;
    margin: 30px 0 20px 0;
  }

  h2 {
    font-size: 30px;
    color: #111111;
    margin: 30px 0 20px 0;
  }

  h1 {
    font-size: 35px;
    color: #111111;
    margin: 30px 0 20px 0;
  }

  p {
    color: #555555;
    font-size: 16px;
  }

  small {
    font-size: 14px;
    color: #555555;
  }

  ul, ol {
    list-style-type: disc;
    margin-left: 21px;
    color: #555555;
    font-size: 16px;
  }
}
