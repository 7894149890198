@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../node_modules/aos/dist/aos.css';
@import '../../../../node_modules/plyr/dist/plyr.css';
@import '../../assets/style/global.scss';
@import '../../components/navigation/navigation.scss';
@import '../../components/footer/footer.scss';
@import '../../components/entry-card/entry-card.scss';
@import '../../components/event-card/event-card.scss';
@import '../../components/note-card/note-card.scss';
@import '../../components/movie-card/movie-card.scss';
@import '../../components/cookie-bar/cookie-bar.scss';

.page-front {
  .header-section {
    min-height: 600px;

    h1 {
      @media(max-width: 991px) {
        font-size: 40px;
      }
    }

    #header-particles {
      display: block;
    }

    .btn-anim {
      bottom: 10px;
      right: 10px;
    }

    //.overlay {
    //  background: rgba(#111111, 0.85);
    //}
  }

  .promo-section {
    padding: 100px 0;

    article {
      &:hover {
        .featured-image {
          transform: scale(1);
        }
      }

      .figure-wrapper {
        height: 350px;

        div {
          transition: 0.3s transform;
          background-position: center;
          background-size: cover;
          transform: scale(1.1);
        }
      }
    }
  }

  .subscribe-section {
    padding: 100px 0;
  }

  .video-section {
    padding: 100px 0;
  }

  .about-section {
    padding: 100px 0;
  }


  .list-section {
    padding: 100px 0;

    @media(max-width: 576px) {
      padding: 20px 0;
    }
    header {
      .aside {
        width: 400px;

        @media(max-width: 767px) {
          width: 100%;
        }
        .line {
          height: 2px;
        }
      }
    }
  }
}
