@import './reset.scss';
@import './libs.scss';
@import './typography.scss';
@import './helpers.scss';
@import './buttons.scss';
@import './colors.scss';

:focus {
  box-shadow: 0 0 0 3px #2e8b68 !important;
}
