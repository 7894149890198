.w-40 {
  width: 40%;
}

.z-index-999 {
  z-index: 999;
}

.opacity-0 {
  opacity: 0;
}

.position-centered {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.transition-03-all {
  transition: 0.3s all;
}

.shadow-small {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.22);
}

.shadow-small-hover:hover {
  box-shadow: 0 14px 48px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.22);
}

.shadow-default {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

